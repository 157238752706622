@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	scrollbar-width: thin;
	scrollbar-color: #286155;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: #286155;
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: #03e6b960;
	border-radius: 14px;
	height: 2px;
	border: 3px solid var(--primary);
}
